@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/themes/light';
@import 'slim-select/dist/slimselect';
@import 'tom-select/dist/css/tom-select';
@import '@uppy/core/dist/style';
@import 'highlight.js/styles/github';
@import 'nouislider/dist/nouislider';
@import 'vendor/flag-icon-css/css/flag-icon';
@import 'fonts';

pre > code {
  font-size: 0.9rem !important;
}

@keyframes notification-countdown {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input.quantity-input::-webkit-outer-spin-button,
input.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].quantity-input {
  -moz-appearance: textfield;
}

/* Fix flickering issue on safari for tiny slider */
.tns-item {
  -webkit-transform: translate3d(0, 0, 0);
}

.my-nested-form > div:nth-last-of-type(2) > div > div:last-child > div:last-child > button:first-child {
  visibility: visible;
}

.my-nested-form > div:first-child > div > div:last-child > div:last-child > button:last-child {
  visibility: hidden;
}
